"use strict";

let today = new Date().toISOString().substr(0, 10);
let day = today.substr(8, 2);
let month = today.substr(5, 2);
let year = today.substr(0, 4);
var popup = document.querySelector(".popup-right");
var button = document.querySelector(".search-btn");

$("#tel")
.mask("7 (000) 000-00-00", {
    /*placeholder: "+7 (___) ___-__-__",*/ clearIfNotMatch: true,
  })
  .focus(function () {
    if (!$(this).val() || $(this).val() == "") {
      $(this).val("7");
    }
  });

$("#date")
  .mask("00.00.0000", { clearIfNotMatch: true })
  .focus(function () {
    if (!$(this).val() || $(this).val() == "") {
      $(this).val(day + "." + month + "." + year);
    }
  });


//scroll up button START
function scrollFunction() {

  var hh = $('#header').height(),
      bh = $('.banner').height(),
      fh = hh + bh + 150 + 240;

  if ((window.innerHeight + window.scrollY) > fh) {
    $("#js-go-up").addClass('_active');
  } else {
    $("#js-go-up").removeClass('_active');
  }

  var mainFooterOffsetTop = $(".main-footer").offset().top,
      mainFooterHeight = $(".main-footer").outerHeight(),
      fullOffset = mainFooterOffsetTop + mainFooterHeight / 4;
  if ((window.innerHeight + window.scrollY) > fullOffset) {
    $("#js-go-up").css('bottom', mainFooterHeight + 43);
  } else {
    $("#js-go-up").css('bottom',  43);
  }

}

$("#js-go-up").click(function(){
  window.scrollTo({top: 0, behavior: 'smooth'});
});

$(document).ready(function() {
  scrollFunction();
});

$(document).scroll(function() {
  scrollFunction();
});

$(window).on('resize', function(){
  scrollFunction();
});


//scroll up button END

//mobile menu
$(document).on("click", ".js-nav-menu-btn", function() {
  $('.js-nav-menu').toggleClass('is-open');
});

//toggle-block
$(document).on("click", ".toggle-block__title-arrow", function() {
  $(this).parent().parent().siblings().removeClass('is-active');
  $(this).parent().parent().toggleClass('is-active');
  let offset = $(this).parent().parent().offset().top;
  window.scrollTo({top: offset, behavior: 'smooth'});
});

//toggle-list
$(document).on("click", ".toggle-list__handler", function() {
  $(this).toggleClass('is-active');
  $(this).siblings('.toggle-list__item').toggleClass('is-active');
  $(this).find('.toggle-list__arrow').toggleClass('_rotated');
});

//popups
$(document).on("click", ".dots-list__icon", function() {
  let id = $(this).data('popup');
  $('#' + id).addClass('is-active');
  setTimeout(function() {
    $('#' + id).find('.popup__content').addClass('is-active');
  }, 50);
});

$(document).on("click", ".popup", function() {
  let popup = $(this),
      popupContent = $(this).find('.popup__content');

  popupContent.removeClass('is-active');
  setTimeout(function() {
    popup.removeClass('is-active');
  }, 300);
});

$('.popup__content').click(function(event){
    event.stopPropagation();
});

//slider
$('.app-slider__wrapper').slick({
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
  prevArrow: $('.app-slider__angle._left'),
  nextArrow: $('.app-slider__angle._right'),
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
});


//add to favourites
$(document).on("click", "#addToFavourites", function() {
  if (window.sidebar && window.sidebar.addPanel) { // Firefox <23

		window.sidebar.addPanel(document.title,window.location.href,'');

	} else if(window.external && ('AddFavorite' in window.external)) { // Internet Explorer

		window.external.AddFavorite(location.href,document.title);

	} else if(window.opera && window.print || window.sidebar && ! (window.sidebar instanceof Node)) { // Opera <15 and Firefox >23
		/**
		 * For Firefox <23 and Opera <15, no need for JS to add to bookmarks
		 * The only thing needed is a `title` and a `rel="sidebar"`
		 * To ensure that the bookmarked URL doesn't have a complementary `#` from our trigger's href
		 * we force the current URL
		 */
		triggerBookmark.attr('rel', 'sidebar').attr('title', document.title).attr('href', window.location.href);
		return true;

	} else { // For the other browsers (mainly WebKit) we use a simple alert to inform users that they can add to bookmarks with ctrl+D/cmd+D

		alert('Вы можете добавить в избранное страницу, нажав комбинацию клавиш ' + (navigator.userAgent.toLowerCase().indexOf('mac') != - 1 ? 'Cmd' : 'CTRL') + ' + D на клавиатуре.');

	}
	// If you have something in the `href` of your trigger
	return false;
});

//exponea form
(function () {
  jQuery.validator.addMethod(
    "lettersonly",
    function (value, element) {
      return this.optional(element) || /^([а-яё]+|[a-z]+)+$/i.test(value);
    },
    "Допускаются только буквы"
  );

  jQuery.validator.addMethod("customemail",
      function(value, element) {
        const substrings = [
          'hotmail.',
          'yahoo.',
          'gmail.',
          'yandex.',
          'ya.',
          'mail.',
          'inbox.',
          'list.',
          'bk.',
          'rambler.',
        ];
        if (new RegExp(substrings.join("|")).test(value)) {
          const arCorrectEmailes = [
            '@hotmail.com',
            '@yahoo.com',
            '@gmail.com',
            '@yandex.ru',
            '@ya.ru',
            '@mail.ru',
            '@inbox.ru',
            '@list.ru',
            '@bk.ru',
            '@rambler.ru',
          ];
          return value && new RegExp(arCorrectEmailes.join("|")).test(value);
        } else {
          return value && /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value);
        }
          //return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value);
      },
      "Введите корректный адрес домена после знака @"
  );

  $("#main-form-short").validate({
    errorElement: "p",
    ignore: ":hidden",
    rules: {
      fio: {
        required: true,
      },

      /*dogovor: {
        required: true,
        minlength: 2,
      },*/

      tel: {
        required: true,

        minlength: 10,
      },
      email: {
        required: true,
        minlength: 5,
        customemail: true,
      },
      /*date: {
        required: true,
      },
      textarea: {
        required: true,
        minlength: 2,
      },*/
    },
    messages: {
      fio: {
        required: "Пожалуйста введите ФИО",
      },
      dogovor: {
        required:
          "Укажите корректный номер договора, это позволит нам оперативно ответить на ваш запрос",
        minlength:
          "Укажите корректный номер договора, это позволит нам оперативно ответить на ваш запрос",
      },
      tel: {
        required: "Пожалуйста введите номер телефона",

        minlength: "Введите данные в указанном формате 79999999999",
      },
      email: {
        required: "Пожалуйста введите email",
        minlength: "Введите данные в указанном формате\nemail@exsample.com",
        customemail: "Введите данные в указанном формате\nemail@exsample.com",
      },
      date: {
        required: "Пожалуйста введите дату рождения",
      },
      textarea: {
        required: "Пожалуйста задайте свой вопрос",
        minlength: "Пожалуйста задайте свой вопрос",
      },
    },
    submitHandler: function (form) {
      
        function getEventProperties(action) {
          return {
            action: action,
            location: window.location.href,
            path: window.location.pathname,
          };
        }

        var eventProperties = getEventProperties('subscribe');
        var email = (form.email.value || "").toLowerCase();
        var phone = parseInt(form.tel.value.replace(/\D+/g, "")) || "";

        eventProperties.email = email;
        eventProperties.phone = phone;
        eventProperties.action = 'subscribe';
        
        exponea.track('campaign', eventProperties);

        var x = document.getElementById("popup__consent")
        x.classList.add("show")
		    window.setTimeout(function(){ x.className = x.classList.remove("show"); }, 3000);

        return false;
      },
  });


  /*$("#main-form-long").validate({
    errorElement: "p",
    ignore: ":hidden",
    rules: {
      fio: {
        required: true,
      },

      dogovor: {
        required: true,
        minlength: 2,
      },

      tel: {
        required: true,

        minlength: 10,
      },
      email: {
        required: true,
        minlength: 5,
        email: true,
      },
      date: {
        required: true,
      },
      textarea: {
        required: true,
        minlength: 2,
      },
    },
    messages: {
      fio: {
        required: "Пожалуйста введите ФИО",
      },
      dogovor: {
        required:
          "Укажите корректный номер договора, это позволит нам оперативно ответить на ваш запрос",
        minlength:
          "Укажите корректный номер договора, это позволит нам оперативно ответить на ваш запрос",
      },
      tel: {
        required: "Пожалуйста введите номер телефона",

        minlength: "Введите данные в указанном формате 79999999999",
      },
      email: {
        required: "Пожалуйста введите email",
        minlength: "Введите данные в указанном формате\nemail@exsample.com",
        email: "Введите данные в указанном формате\nemail@exsample.com",
      },
      date: {
        required: "Пожалуйста введите дату рождения",
      },
      textarea: {
        required: "Пожалуйста задайте свой вопрос",
        minlength: "Пожалуйста задайте свой вопрос",
      },
    },
    submitHandler: function (form) {
        button.disabled = true;
        function getEventProperties(action) {
          return {
            action: action,
            location: window.location.href,
            path: window.location.pathname,
          };
        }

        function validateEmail(email) {
          return email && /^\S+@\S+\.\S+$/.test(email);
        }
        var eventProperties = getEventProperties("question");
        var email = (form.email.value || "").toLowerCase();
        var phone = parseInt(form.tel.value.replace(/\D+/g, "")) || "";
        var FIO = form.fio.value || "";
        var POLICY_NO = form.dogovor.value;
        var DATE = Math.round(
          new Date(form.date.value.split(".").reverse().join("-")).getTime() /
            1000
        );
        var question = form.textarea.value.replace(/\n/g, "<br />");

        eventProperties.subscription_email = email;
        eventProperties.subscription_phone = phone;
        eventProperties.subscription_FIO = FIO;
        eventProperties.subscription_POLICY_NO = POLICY_NO;
        eventProperties.subscription_DATE = DATE;
        eventProperties.subscription_question = question;

        exponea.track("subscribe", eventProperties);
        if (validateEmail(email)) {
          exponea.identify({
            phone: phone,
            email: email,
          });
        }

        popup.style.display = "flex";
        setTimeout(function () {
          popup.style.display = "none";
        }, 3000);

        button.disabled = false;

        return false;
      },
  });*/
})();
